exports.onRouteUpdate = () => {
    if (typeof window !== `undefined`) { window.scrollTo(0, 0)}
}
 
exports.shouldUpdateScroll = () => false;

exports.onRouteUpdate = ({ location }) => scrollToAnchor(location);
function scrollToAnchor(location) {

    // Check for location so build does not fail
    if(location && location.hash) {

        // define target element
        const thisHASH = location.hash.split('#')[1];
        const item = document.getElementById(thisHASH);

        // check if dom item found
        if(item) {

            setTimeout(function() {

                // define top position
                const thisTOP = item.offsetTop;

                // scroll to element
                window.scrollTo({
                    top: (thisTOP + 0),
                    behavior: "smooth",
                })
            }, 250);
        }

    }
  
    return true
}