exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basic-detail-js": () => import("./../../../src/pages/basic-detail.js" /* webpackChunkName: "component---src-pages-basic-detail-js" */),
  "component---src-pages-content-detail-js": () => import("./../../../src/pages/content-detail.js" /* webpackChunkName: "component---src-pages-content-detail-js" */),
  "component---src-pages-faq-detail-js": () => import("./../../../src/pages/faq-detail.js" /* webpackChunkName: "component---src-pages-faq-detail-js" */),
  "component---src-pages-faq-overview-js": () => import("./../../../src/pages/faq-overview.js" /* webpackChunkName: "component---src-pages-faq-overview-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-orientation-js": () => import("./../../../src/pages/orientation.js" /* webpackChunkName: "component---src-pages-orientation-js" */),
  "component---src-pages-product-detail-js": () => import("./../../../src/pages/product-detail.js" /* webpackChunkName: "component---src-pages-product-detail-js" */),
  "component---src-pages-product-overview-js": () => import("./../../../src/pages/product-overview.js" /* webpackChunkName: "component---src-pages-product-overview-js" */),
  "component---src-pages-search-overview-js": () => import("./../../../src/pages/search-overview.js" /* webpackChunkName: "component---src-pages-search-overview-js" */)
}

